import React from 'react';
import RoutesWrapper from "./Routes/index";
import "antd/dist/antd.css";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import QueryProvider from './services/react-query';
import { ConfigProvider } from "antd"; // Removed `App as AntApp`
import { IntlProvider } from "react-intl";
import { appTheme } from "./utils/constants";

function App() {
  return (
    <QueryProvider>
      <IntlProvider>
        <ConfigProvider
          direction="ltr"
          theme={appTheme}
          virtual
        >
          <RoutesWrapper />
        </ConfigProvider>
      </IntlProvider>
    </QueryProvider>
  );
}

export default App;
