import { createSlice } from '@reduxjs/toolkit';

const serviceSlice = createSlice({
  name: 'Servicey',
  initialState: {
    info: [],
    teamMember: []
  },
  reducers: {
    setServiceInfo: (info, action) => {
      info.info = action.payload;
    },
    setServiceTeamMember: (teamMember, action) => {
      teamMember.teamMember = action.payload;
    },
    resetServiceState: (state) => {
      state.info = [];
      state.teamMember = [];
    }
  },
});

export const { setServiceInfo, setServiceTeamMember, resetServiceState } = serviceSlice.actions;
export default serviceSlice.reducer;
