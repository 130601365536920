import { axiosInstance } from "../services/axiosInstance";
import catchAsync from "../utils/catchAsync";
import i18next from "i18next";

export const listProducts = catchAsync(async () => {
  const { data } = await axiosInstance.get('products', {
    headers: {
      // "Accept-Language": i18next.language,
    }
  });
  return data;
});


export const productDetails = async (id) => {
  const { data } = await axiosInstance.get(`products/${id}`, {
    headers: {
      // "Accept-Language": i18next.language,
    }
  });
  return data;
};


export const addProduct = catchAsync(async (body) => {
  const { data } = await axiosInstance.post(`/products`, body, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });

  return data;
});