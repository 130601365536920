// rootReducer.js

import { combineReducers } from "@reduxjs/toolkit";
import userReducer from "./slices/user";
import serviceReducer from "./slices/service";

/**
 * @typedef {Object} RootState - The root state object.
 * @property {ServiceState} service - The category slice state.
 */

export default combineReducers({
  user: userReducer,
  service: serviceReducer,
});
