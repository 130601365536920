import {
  Row,
  Col,
  Card,
  Spin,
} from "antd";
import { useQuery } from "@tanstack/react-query";
import { listProducts } from "../network/product";
import { queryKeys } from "../services/react-query/queryKeys";
import { useLocation } from "react-router-dom";

function Chapters() {
  const location = useLocation();
  const courseData = location.state?.courseData;

  const { data: chapterData, isLoading, refetch } = useQuery({
    queryKey: [queryKeys.listProducts],
    queryFn: listProducts,
  });

  if (isLoading) {
    return (
      <div className="flex gap-4 items-center justify-center">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col xs={24} xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
          >

          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Chapters;
