import { FC } from "react";
import React from "react";
import { Button, message, Modal } from "antd";
import { useMutation } from "@tanstack/react-query";
import ClockCircleOutlined from '@ant-design/icons/ClockCircleOutlined';
import DeleteImg from "../../assets/images/delete.png";

interface DeleteModalProps<T> {
    visible: boolean;
    onClose: () => void;
    onDelete: (data: T) => Promise<void>;
    data: T | null;
    confirmationHeader: any;
    queryKey: string;
    optionalDiv?: any | null;
}

const DeleteModal: FC<DeleteModalProps<any>> = ({
    visible,
    onClose,
    onDelete,
    data,
    confirmationHeader,
    queryKey,
}) => {
    // const { mutate: deleteMutation, isPending } = useMutation({
    //     mutationFn: (params) => 
    //         addProduct(params),
    //     onSuccess: () => {
    //     //   queryClient.invalidateQueries([queryKey]);
    //     },
    //     onError: (error) => {
    //       console.error("Error adding product:", error);
    //     },
    //   });

    const handleDelete = async () => {
        if (data) {
            // deleteMutation(data);
        }
    };

    return (
        <Modal
            centered
            open={visible}
            className="customModal"
            footer={[]}
            title={[""]}
            onCancel={onClose}
            width={850}
        >
            <div className="text-center p-5" >
                <div className="my-8">
                    <img
                        src={DeleteImg}
                        alt="" style={{ height: "200px" }} />
                </div>
                <div className="mb-4">
                    <div>
                        <span>
                            <ClockCircleOutlined className="text-[#E30613]" />
                        </span>
                        <span className="px-1" style={{ fontWeight: 500 }}>
                            Are you sure delete the {confirmationHeader}
                        </span>
                    </div>
                    <div>
                        you will lose all your data
                    </div>
                </div>

                <div className="flex gap-4 items-center justify-center">
                    <Button
                        type="text"
                        className="h-full text-lg rounded-md px-2 w-[112px]"
                        key="cancel"
                        onClick={onClose}
                    >
                        Cancel
                    </Button>
                    <Button
                        key="ok"
                        className="bg-[#cf2110] h-full text-white text-lg rounded-md px-2 w-[112px]"
                        htmlType="submit"
                        type="text"
                        onClick={handleDelete}
                    // loading={isPending}
                    >
                        Delete
                    </Button>
                </div>
            </div>
        </Modal>
    );
};

export default DeleteModal;
