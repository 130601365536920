import {
  Modal,
  Form,
  Row,
  Col,
  Select,
  Input,
  Button,
  DatePicker,
  InputNumber,
  Switch,
} from "antd";
import { useMutation, useQuery } from "@tanstack/react-query";
import { queryKeys } from "../../services/react-query/queryKeys";
import { addProduct } from "../../network/product";
import { listCategories } from "../../network/category";
import { lang } from "../../enum/lang";

function AddCourseModal({ visible, onCancel, refetchCourses }) {
  const [form] = Form.useForm();

  const { data: categoriesData, isLoading } = useQuery({
    queryKey: [queryKeys.listCategories],
    queryFn: listCategories,
  });

  const { mutate: addCourseMutation, isLoading: isAddProductPending } = useMutation({
    mutationFn: (params) => addProduct(params),
    onSuccess: () => {
      refetchCourses();
    },
    onError: (error) => {
      console.error("Error adding product:", error);
    },
  });

  const onFinish = (values) => {
    addCourseMutation(values);
  };

  return (
    <Modal
      centered
      open={visible}
      className="customModal"
      onCancel={onCancel}
      footer={null}
      title="Add Course"
      width={1050}
    >
      <Form
        onFinish={onFinish}
        name="course-form"
        layout="vertical"
        form={form}
        encType="multipart/form-data"
      >
        <Row gutter={24}>
          <Col xl={8}>
            <Form.Item
              required={false}
              name="language_id"
              label="Language"
              rules={[{ required: true, message: "Language is required" }]}
            >
              <Select
                placeholder="Choces language"
                options={lang.map((item) => ({
                  value: item.value,
                  label: item.label,
                }))}
              />
            </Form.Item>
          </Col>
          <Col xl={8}>
            <Form.Item
              required={false}
              name="name"
              label="Course Name"
              rules={[{ required: true, message: "Course Name is required" }]}
            >
              <Input placeholder="Enter Course Name" />
            </Form.Item>
          </Col>
          <Col xl={8}>
            <Form.Item
              required={false}

              name="type"
              label="Course Type"
              rules={[{ required: true, message: "Course Type is required" }]}
            >
              <Input placeholder="Enter Course Type" />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xl={8}>
            <Form.Item
              required={false}
              name="slug"
              label="Slug"
              rules={[{ required: true, message: "Slug is required" }]}
            >
              <Input placeholder="Enter Slug" />
            </Form.Item>
          </Col>
          <Col xl={8}>
            <Form.Item
              required={false}
              name="posterUrl"
              label="Poster URL"
              rules={[{ required: true, message: "Poster URL is required" }]}
            >
              <Input placeholder="Enter Poster URL" />
            </Form.Item>
          </Col>
          <Col xl={8}>
            <Form.Item
              required={false}

              name="price"
              label="Price"
              rules={[{ required: true, message: "Price is required" }]}
            >
              <InputNumber placeholder="Enter Price" style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xl={8}>
            <Form.Item required={false}
              name="discountPrice" label="Discount Price">
              <InputNumber placeholder="Enter Discount Price" style={{ width: "100%" }} />
            </Form.Item>
          </Col>
          <Col xl={8}>
            <Form.Item required={false}
              name="location" label="Location">
              <Input placeholder="Enter Location" />
            </Form.Item>
          </Col>
          <Col xl={8}>
            <Form.Item required={false}
              name="date" label="Date">
              <DatePicker style={{ width: "100%" }} />
            </Form.Item>
          </Col>
        </Row>
        <Row gutter={24}>
          <Col xl={8}>
            <Form.Item required={false}
              name="status" label="Status" valuePropName="checked">
              <Switch defaultChecked />
            </Form.Item>
          </Col>
          <Col xl={8}>
            <Form.Item required={false}
              name="caption" label="Caption" valuePropName="checked">
              <Switch />
            </Form.Item>
          </Col>
          <Col xl={8}>
            <Form.Item
              required={false}
              name="shortDesc"
              label="Short Description"
              rules={[{ required: false, message: "Short Description is required" }]}
            >
              <Input placeholder="Enter Short Description" />
            </Form.Item>
          </Col>
        </Row>
        <Form.Item name="description" label="Description">
          <Input.TextArea placeholder="Enter Course Description" rows={4} />
        </Form.Item>
        <div className="flex gap-4 justify-end">
          <Button onClick={onCancel} className="bg-gray-500 text-white">
            Cancel
          </Button>
          <Button
            type="primary"
            loading={isAddProductPending}
            htmlType="submit"
            className="text-white"
          >
            Save
          </Button>
        </div>
      </Form>
    </Modal>
  );
}

export default AddCourseModal;
