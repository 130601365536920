import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Typography,
  Tooltip,
  message,
  Spin,
} from "antd";
import { useQuery } from "@tanstack/react-query";
import { listProducts } from "../network/product";
import { queryKeys } from "../services/react-query/queryKeys";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import AddCourseModal from "../components/course/AddCourse";
import EditCourseModal from "../components/course/EditCourse";
import DeleteModal from "../components/common/DeleteModal";
import {
  DeleteOutlined,
  EditOutlined,
  FilterOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import useTableSearch from "../hooks/useTableSearch";

const { Title } = Typography;

function Courses() {
  const [modalState, setModalState] = useState({
    addCourse: false,
    editCourse: false,
    deleteCourse: false,
    selectedCourse: null,
  });

  const { getColumnSearchProps } = useTableSearch();

  const { data: productData, isLoading, refetch } = useQuery({
    queryKey: [queryKeys.listProducts],
    queryFn: listProducts,
  });

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      ...getColumnSearchProps("name"),
      render: (name) => <Title level={5}>{name}</Title>,
    },
    {
      title: "Price",
      dataIndex: "price",
      key: "price",
      sorter: (a, b) => a.price - b.price,
      filters: productData?.data
        ? Array.from(new Set(productData.data.map((item) => item.price))).map(
            (price) => ({ text: `$${price}`, value: price })
          )
        : [],
      onFilter: (value, record) => record.price === value,
      render: (price) => <span>${price}</span>,
    },
    {
      title: "Description",
      key: "description",
      dataIndex: "description",
      render: (description) => (
        <Tooltip title={description}>
          <span>
            {description.length > 50
              ? `${description.substring(0, 50)}...`
              : description}
          </span>
        </Tooltip>
      ),
    },
    {
      title: "Created At",
      key: "created_at",
      filterSearch: true,
      dataIndex: "created_at",
      sorter: (a, b) => new Date(a.created_at) - new Date(b.created_at),
      filters: productData?.data
        ? Array.from(
            new Set(
              productData.data.map((item) =>
                new Date(item.created_at).toLocaleDateString()
              )
            )
          ).map((date) => ({ text: date, value: date }))
        : [],
      onFilter: (value, record) =>
        new Date(record.created_at).toLocaleDateString() === value,
      render: (createdAt) => <span>{new Date(createdAt).toLocaleDateString()}</span>,
    },
    {
      title: "Actions",
      key: "actions",
      render: (_, record) => (
        <div style={{ display: "flex", gap: "12px" }}>
          <Tooltip title="Edit Course">
            <EditOutlined
              style={{ color: "gray", cursor: "pointer" }}
              onClick={() => handleModal("editCourse", true, record)}
            />
          </Tooltip>
          <Tooltip title="Delete Course">
            <DeleteOutlined
              style={{ color: "red", cursor: "pointer" }}
              onClick={() => handleModal("deleteCourse", true, record)}
            />
          </Tooltip>
          <Tooltip title="Show Chapters">
            <Link
              to="/chapters"
              style={{ color: "#1890ff", cursor: "pointer" }}
              state={{ courseData: record }}
            >
              <UnorderedListOutlined />
            </Link>
          </Tooltip>
        </div>
      ),
    },
  ];

  const handleModal = (modalType, visibility, course = null) => {
    setModalState((prevState) => ({
      ...prevState,
      [modalType]: visibility,
      selectedCourse: course,
    }));
  };

  if (isLoading) {
    return (
      <div className="flex gap-4 items-center justify-center pt-[200px]">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col xs={24} xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Courses Table"
            extra={
              // <div style={{ display: "flex", gap: "12px" }}>
                <Button onClick={() => handleModal("addCourse", true)}>
                  Add Course
                </Button>
              // </div>
            }
          >
            <div className="table-responsive">
              <Table
                columns={columns}
                dataSource={productData?.data}
                pagination={{ pageSize: 10 }}
                className="ant-border-space p-1"
                rowKey="id"
              />
            </div>
          </Card>
        </Col>
      </Row>

      <AddCourseModal
        visible={modalState.addCourse}
        onCancel={() => handleModal("addCourse", false)}
        refetchCourses={refetch}
      />

      <EditCourseModal
        visible={modalState.editCourse}
        onCancel={() => handleModal("editCourse", false)}
        course={modalState.selectedCourse}
        refetchCourses={refetch}
      />

      <DeleteModal
        visible={modalState.deleteCourse}
        onClose={() => handleModal("deleteCourse", false)}
        onDelete={() => {
          refetch();
        }}
        data={modalState.selectedCourse}
        confirmationHeader={modalState.selectedCourse?.name}
        queryKey={queryKeys.listProducts}
      />
    </div>
  );
}

export default Courses;