import {
  Row,
  Col,
  Card,
  Table,
  Button,
  Avatar,
  Typography,
  Tooltip,
  Flex,
  Spin
} from "antd";
import { useQuery } from "@tanstack/react-query";
import { listProducts } from "../network/product";
import { queryKeys } from "../services/react-query/queryKeys";
import { useState } from "react";
import AddCourseModal from "../components/course/AddCourse";

const { Title } = Typography;

function Customers() {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { data: productData, isLoading, refetch } = useQuery({
    queryKey: [queryKeys.listProducts],
    queryFn: listProducts,
  });

  const columns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name",
      render: (name) => (
        <Title level={5}>{name}</Title>
      ),
    },
    {
      title: "Created At",
      key: "created_at",
      dataIndex: "created_at",
      render: (createdAt) => <span>{new Date(createdAt).toLocaleDateString()}</span>,
    },
  ];

  const handleAddCourse = () => {
    setIsModalVisible(true);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  if (isLoading) {
    return (
      <div className="flex gap-4 items-center justify-center pt-[200px]">
        <Spin size="large" />
      </div>
    );
  }

  return (
    <div className="tabled">
      <Row gutter={[24, 0]}>
        <Col xs={24} xl={24}>
          <Card
            bordered={false}
            className="criclebox tablespace mb-24"
            title="Customers Table"
            extra={
              <Button onClick={handleAddCourse}>
                Add Customers
              </Button>
            }
          >
            <div className="table-responsive">
              <Table
                columns={columns}
                dataSource={null}
                pagination={{ pageSize: 10 }}
                className="ant-border-space p-1"
                rowKey="id"
              />
            </div>
          </Card>
        </Col>
      </Row>
    </div>
  );
}

export default Customers;
