import { Navigate, useRoutes } from "react-router-dom";
import OwnNotFound from "../pages/OwnNotFound";
import Main from "../components/layout/Main";
import SignIn from "../pages/SignIn";
import Home from "../pages/Home";
import Courses from "../pages/Courses";
import Profile from "../pages/Profile";
import Sales from "../pages/Sales";
import Customers from "../pages/Customers";
import Podcasts from "../pages/Podcasts";
import Purchases from "../pages/Purchases";
import Chapters from "../pages/Chapters";
import { getStoredToken } from "../services/user-storage";

function RoutesWrapper() {
  const isAuthenticated = getStoredToken() !== null;

  const routes = useRoutes([
    {
      path: "*",
      element: <OwnNotFound />,
    },
    {
      path: "/",
      element: isAuthenticated ? <Main /> : <Navigate to="/sign-in" replace />,
      children: [
        {
          path: "/",
          element: <Home />,
        },
        {
          path: "courses",
          element: <Courses />,
        },
        {
          path: "purchases",
          element: <Purchases />,
        },
        {
          path: "podcasts",
          element: <Podcasts />,
        },
        {
          path: "chapters",
          element: <Chapters />,
        },
        {
          path: "sales",
          element: <Sales />,
        },
        {
          path: "customers",
          element: <Customers />,
        },
        {
          path: "profile",
          element: <Profile />,
        },
      ],
    },
    {
      path: "/sign-in",
      element: <SignIn />,
    },
  ]);

  return routes;
}

export default RoutesWrapper;
